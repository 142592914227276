import * as React from 'react';
import { Box, Button, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { createContext } from 'react';
import emailjs from '@emailjs/browser';
import { ContactForm } from '../types/ContactForm';
import { useForm } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';

const SERVICE_ID=`${process.env.REACT_APP_EMAIL_JS_SERVICE_ID}`;
const TEMPLATE_ID=`${process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID}`;
const PUBLIC_KEY=`${process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY}`;

export default function ContactUsSection() {

    const { register, handleSubmit } = useForm<ContactForm>();

    // const context = createContext({
    //     contactFormState: null,
    //     setContactFormState: () => undefined
    // });

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<number>();

    const buttonSx = {
        ...(success && {
        bgcolor: green[500],
        '&:hover': {
            bgcolor: green[700],
        },
        }),
    };

    const sendEmail = (data: ContactForm) => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            const emailParams = {
                name: data.name,
                email: data.email,
                message: data.message
            }
            emailjs.send(SERVICE_ID, TEMPLATE_ID, emailParams, PUBLIC_KEY)
            .then((result) => {
                setSuccess(true);
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            })
            .finally(() => {
                setLoading(false);
            });

        }
    }

    

    return (
        <Box sx={{ py: 3 }}>
            <Typography variant="h4" gutterBottom>Contact Us</Typography>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Typography variant='h6'>Want to contact us?</Typography>
                    <br></br>
                    <Typography variant='body1'>Either fill out our contact form or email us at hello@greydek.biz and someone will get back to you soon</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                            '& .MuiTextField-root': { m: 1, width: '50ch' },
                        }} >
                            <form onSubmit={handleSubmit(sendEmail)}>
                        <TextField  
                            id="name-input"
                            label="Name"
                            type="text"
                            required
                            {...register("name")}
                        />
                        <br></br>
                        <TextField  
                            id="email-input"
                            label="Email"
                            type="text"
                            required
                            {...register("email")}
                        />
                        <br></br>
                        <TextField  
                            id="message-input"
                            label="Message"
                            type="text"
                            multiline
                            required
                            {...register("message")}
                        />
                        <br></br>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button 
                                variant="contained"
                                sx={buttonSx}
                                disabled={loading} 
                                color="primary" 
                                type="submit">
                                
                                {success ? <CheckIcon /> : <Typography>Send</Typography>}
                                {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    }}
                                />
                                )}
                            </Button>
                            
                        </Box>
                        </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}