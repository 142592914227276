import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ServiceCard from './DetailCard';
import data from '../content/services-data.json';
import { Box } from '@mui/material';
import gap_fills from '../assets/gap_fills.jpg';
import merger from '../assets/merger.jpg';
import mentoring from '../assets/mentoring.jpg';
import support from '../assets/support.jpg';
import performance from '../assets/performance.jpg';

export default function ServicesSection() {
    data.serviceList[0].image = gap_fills;
    data.serviceList[1].image = merger;
    data.serviceList[2].image = mentoring;
    data.serviceList[3].image = support;
    data.serviceList[4].image = performance;

    const cardHeight=300;
    return (
        <Box sx={{ pb: 3 }}>
            <Typography variant="h4">Services</Typography>
            <Container maxWidth="lg">
            <Typography sx={{ py: 1 }} variant='body1'>{data.intro}</Typography>
                <Grid container spacing={4}>
                {data.serviceList.map((service) =>
                    <ServiceCard
                        key={service.header} 
                        title={service.header}
                        content={service.bullets}
                        image={service.image}
                        imageText={service.imageText}
                        cardHeight={cardHeight}></ServiceCard>
                )}    
                </Grid>
            </Container>
        </Box>
    )
}