import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import data from '../content/about-us-data.json';
import Divider from '@mui/material/Divider';
import DetailCard from './DetailCard';
import profile_jl from '../assets/John_Lourenco.jpg';
import profile_dw from '../assets/Dan_Wellington.png';


export default function AboutUsSection() {
    const profileStyle={ height: 200, borderRadius: "50%", justify: "center" }
    const cardHeight=500;
    data.founders[0].image = profile_jl;
    data.founders[1].image = profile_dw;
    return (
        <Box sx={{ py: 3 }}>
            <Typography variant="h4" gutterBottom>About Us</Typography>
            <Container maxWidth="lg">
                <Grid container spacing={4} sx={{ mt: 3 }}>
                    
                    <Typography variant='body1' sx={{ py: 1 }}>{data.intro}</Typography>
                    <Divider />
                    <Typography variant='h6' sx={{ py: 1 }}>{data.heading}</Typography>
                    {data.paragraphs.map((paragraph) => 
                        <Typography key={paragraph} variant='body1'>{paragraph}</Typography>
                    )}
                </Grid>

                <Grid container spacing={4} sx={{ mt: 3 }}>
                    {data.founders.map((founder) =>
                    <DetailCard 
                        key={founder.name}
                        title={founder.name}
                        content={founder.slug}
                        image={founder.image}
                        imageText={founder.imageText}
                        imageStyle={profileStyle}
                        cardHeight={cardHeight}
                    ></DetailCard>
                    )}
                </Grid>
            </Container>
        </Box>
    )
}