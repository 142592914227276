import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Divider } from '@mui/material';

interface DetailCardProps {
  title:string;
  content: string[];
  image:string;
  imageText:string;
  imageStyle?: {};
  cardHeight?: number;
}

export default function DetailCard(props: DetailCardProps) {
  return (
    <Grid item xs={12} md={6}>
      <CardActionArea component="a" href="#">
        <Card sx={{ height: props.cardHeight, display: 'flex' }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              {props.title}
            </Typography>
              {props.content.map((text) =>
              <Typography key={text} variant="subtitle1" color="text.secondary">
                {text}  
                <Divider/>
              </Typography>
              
              )}
          </CardContent>
          <CardMedia
            component="img"
            sx={{ width: 200, display: { xs: 'none', sm: 'block' }, ...props.imageStyle} }
            image={props.image}
            alt={props.imageText}
          />
        </Card>
      </CardActionArea>
    </Grid>
  );
}
