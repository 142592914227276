import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import data from '../content/main-data.json';
import MainBanner from './MainBanner';
import ServicesSection from './Services';
import AboutUsSection from './AboutUs';
import ContactUsSection from './ContactUs';
import Divider from '@mui/material/Divider';

const theme = createTheme();

export default function Main() {
  const { sections, mainBanner } = data;
  return (
  <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="Greydek" sections={sections} />
        <main>
          <MainBanner 
            image={mainBanner.image} 
            imageText={mainBanner.imageText}
            slug={mainBanner.slug}></MainBanner>
           
          <ServicesSection></ServicesSection>
          <Divider sx={{ bgcolor: '#c0cbff' }}/>
          <AboutUsSection></AboutUsSection>
          <Divider sx={{ bgcolor: '#c0cbff' }}/>
          <ContactUsSection></ContactUsSection>
        </main>
      </Container>
      <Footer
        title="GreyDek"
        description="hello@greydek.biz"
      />
    </ThemeProvider>
  );
  // const { posts, title } = props;

  // return (
  //   <Grid
  //     item
  //     xs={12}
  //     md={8}
  //     sx={{
  //       '& .markdown': {
  //         py: 3,
  //       },
  //     }}
  //   >
  //     <Typography variant="h6" gutterBottom>
  //        {title}
  //     </Typography>
  //     <Divider />
  //     {posts.map((post) => (
  //       <Markdown className="markdown" key={post.substring(0, 40)}>
  //         {post}
  //       </Markdown>
  //     ))}
  //   </Grid>
  // );
}
